<template>
  <div>
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <el-switch
            v-model="field.validations.mapViewRequired"
            active-color="#13ce66"
            inactive-color="#ff4949"
            class="mb-10"
            active-text="Enable in MapView"
          >
          </el-switch>
          <el-switch
            v-model="field.validations.AllowEdit"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="AllowEdit"
          >
          </el-switch>
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" class="field-required" />
        </el-col>
        <el-col :span="10">
          <div class="icon-dropdown-selection">
            <el-button type="text" @click="iconPickerdialogVisible = true">
              <div class="d-flex align-center menu-icon">
                <span>
                  <icons :iconName="templateIconPath"></icons>
                </span>
                <i class="el-icon-circle-plus"></i>
              </div>
            </el-button>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <el-checkbox v-model="field.validations.geoFenceRequired"
            >Setup Geofence</el-checkbox
          >
        </el-col>
        <el-col :span="8">
          <span>Enter Fence Radius</span>
          <el-input-number
            v-if="field.validations.geoFenceRequired"
            v-model="field.validations.geoFenceRadius"
            controls-position="right"
            :min="1"
            :max="50000"
          ></el-input-number>
        </el-col>
        <el-col :span="8">
          <span>Enter Fence Center Address (or) co-ordinates</span>
          <el-input
            v-model="field.validations.geoFenceCoordinates"
            placeholder="Enter Address"
            @change="getEpicenter"
          ></el-input>
        </el-col>
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <is-field-required :field="field" class="field-required" />
    </el-form>
    <dialog-component
      :title="'Select An Icon'"
      :visible="iconPickerdialogVisible"
      :containerWidth="getIsMobile ? '100%' : '77%'"
      @before-close="closeSelectIcon"
      :isShowFooter="false"
      :selectedIcon="field.icon_name"
    >
      <div class="icons-component">
        <div class="iconslistScrollable">
          <icons-list @icon-data="getIconPath"></icons-list>
        </div>
      </div>
    </dialog-component>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
//  import IsFieldRequired from "./IsFieldRequired";
// import FieldFilledBy from "./FieldFilledBy";
// import FieldAttributes from "./FieldAttributes";
// import Placeholder from "./Placeholder";
//import { mapGetters } from "vuex";
import { fetchGlobalVariables } from "@/repo/globalVariables"
import axios from "axios";
export default {
  name: "templates-formComponents-Location",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    // "Placeholder":()=>import("./Placeholder"),
  },

  props: ["field"],
  computed: {
    //...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
  },
  mounted() {
    this.fetchGlobalVaribales();
  },
  data() {
    return {
      iconPickerdialogVisible: false,
      getAllGlobalVariables: null,
    };
  },
  methods: {
    closeSelectIcon() {
      this.iconPickerdialogVisible = false;
    },
    getIconPath(icon) {
      this.templateIconPath = icon;
      this.$set(this.field, "icon_name", icon);
      this.iconPickerdialogVisible = false;
    },
    parseCoordinates() {
      // Example Google Maps URL: https://www.google.com/maps/place/37.7749,-122.4194
      const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
      const match = this.googleMapsUrl.match(regex);

      if (match) {
        const latitude = parseFloat(match[1]);
        const longitude = parseFloat(match[2]);
        this.center = [latitude, longitude];
        this.coordinates = [latitude, longitude];
        // Optionally, you can set the zoom level based on your requirements
        this.zoom = 15;
        // Optionally, pan the map to the new coordinates
        this.$refs.map.panTo([latitude, longitude]);
      }
    },
    async getEpicenter(address) {
      try {
        // These are query Params to make API call
        const config = {
          params: {
            address: address,
            sensor: true,
            key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
          },
        };

        // Make the API request using the config Params object
        const response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json",
          config
        );

        // Extract coordinates from response
        const coordinates = response.data.results[0].geometry.location;
        // Update the coordinates for the marker to point
        this.field.validations.geoFenceCoordinates = [
          coordinates.lat,
          coordinates.lng,
        ];
        this.$set(
          this.field,
          this.validations.geoFenceCoordinates,
          `${this.coordinates[0]}, ${this.coordinates[1]}`
        );
      } catch (error) {
        console.error("getCoordinates", error);
        return null;
      }
    },
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: this.field.input_type,
      };
      this.getAllGlobalVariables = await fetchGlobalVariables(params);
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.options = globalVariable.options;
    },
  },
};
</script>

<style lang="scss" scoped>
.map {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
